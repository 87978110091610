import './App.css';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import RSVP from './components/RSVP';
import EventInfo from './components/EventInfo';

function App() {
  return (
    <Router>
      <div className="App">
        <div className="scroll-container">
          <div className="scroll-item">
            <EventInfo />
          </div>
          <div className="scroll-item">
            <RSVP />
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;