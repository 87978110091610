import React from 'react';

const EventInfo = () => {
  return (
    <div className="min-h-screen bg-blue-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white border-4 border-blue-200 rounded-lg shadow-xl overflow-hidden">
        <div className="px-6 py-5 sm:p-6">
          <h2 className="text-center text-3xl font-bold text-blue-800 font-serif mb-6">Event Information</h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-xl font-semibold text-blue-700 mb-2">Wedding Ceremony & Reception</h3>
              <p className="text-blue-600"><strong>Date:</strong> Saturday, October 15, 2024</p>
              <p className="text-blue-600"><strong>Time:</strong> 4:00 PM - 11:00 PM</p>
              <p className="text-blue-600"><strong>Location:</strong> Lovely Wedding Venue, 123 Celebration Lane, Romantic City, RC 12345</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-blue-700 mb-2">Schedule</h3>
              <ul className="list-disc list-inside text-blue-600">
                <li>4:00 PM - Ceremony</li>
                <li>5:00 PM - Cocktail Hour</li>
                <li>6:00 PM - Dinner</li>
                <li>7:30 PM - Dancing</li>
                <li>11:00 PM - Send-off</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-blue-700 mb-2">Attire</h3>
              <p className="text-blue-600">Semi-formal / Cocktail</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-blue-700 mb-2">Additional Information</h3>
              <p className="text-blue-600">Please note that the venue is outdoors. We recommend bringing a light jacket for the evening.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventInfo;