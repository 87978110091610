import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { motion, AnimatePresence } from 'framer-motion';
import confetti from 'canvas-confetti';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
// Utility functions to group Tailwind classes
const baseInputStyles = `
  appearance-none block w-full sm:text-sm
`;

const paddingAndSizing = `
  px-3 py-3 mb-10 mt-10
`;

const borderAndFocusStyles = `
  border border-blue-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10
`;

const colorStyles = `
  placeholder-blue-400 text-blue-900
`;

const shadowStyle = `
  shadow-lg
`;

// Refactored FormattedText component
const FormattedText = ({
  value,
  onChange,
  label,
  id,
  variant = "outlined",
  required = false,
  type = "text",
  multiline = false,
  rows = 1,
}) => {
  const combinedClassName = `${baseInputStyles} ${paddingAndSizing} ${borderAndFocusStyles} ${colorStyles} ${shadowStyle}`;
  
  return (
    <TextField
      className={combinedClassName}
      value={value}
      onChange={onChange}
      id={id}
      label={label}
      variant={variant}
      required={required}
      type={type}
      multiline={multiline}
      rows={rows}
      fullWidth
    />
  );
};
const CelebrationModal = ({ isOpen, onClose, isAttending }) => {
  useEffect(() => {
    if (isOpen && isAttending) {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
      });

    }
    // console.log(attending)
    console.log(isAttending)
    console.log(isOpen)
  }, [isOpen, isAttending]);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={onClose}
        >
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.5, opacity: 0 }}
            className="bg-white p-8 rounded-lg shadow-xl text-center"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-3xl font-bold text-blue-900 mb-4">Thank You!</h2>
            {isAttending ? (
              <>
                <p className="text-xl text-blue-700 mb-6">Your RSVP has been received.</p>
                <p className="text-lg text-blue-600">We look forward to celebrating with you!</p>

                <AddToCalendarButton
                  name="Valerie & Calvin Party"
                  startDate="2024-12-13"
                  startTime='21:00'
                  endDate='2024-12-14'
                  endTime='01:00'
                  options={['Apple','Google']}
                  timeZone="Asia/Hong_Kong"
                  buttonStyle='date'

                ></AddToCalendarButton>
              </>
            ) : (
              <>
                <p className="text-xl text-blue-700 mb-6">We've received your response.</p>
                <p className="text-lg text-blue-600">We're sorry you can't make it, but we appreciate you letting us know.</p>
              </>
            )}
            <button
              className="mt-6 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-300"
              onClick={onClose}
            >
              Close
            </button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};


// The refactored component
const RusticRSVP = () => {
  const [pronoun, setPronoun] = useState('');
  const [name, setName] = useState('');
  const [attending, setAttending] = useState('');
  const [guests, setGuests] = useState(1);
  const [dietaryRestrictions, setDietaryRestrictions] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    const formData = {
      name,
      attending,
      pronoun: pronoun,
      email: email,
      guests: attending === 'yes' ? guests : '',
      dietaryRestrictions: attending === 'yes' ? dietaryRestrictions : '',
    };

    try {
      await fetch('https://script.google.com/macros/s/AKfycby8cYndTq6qkmON_pZPEa_NuLYu-sqKY4wu1cM6I0cc-MhV1z-5_y7_LXQCljH9XSDf/exec', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      setSubmitMessage('Thank you for your RSVP!');
      setIsModalOpen(true);
      // Reset form
      setName('');
      setPronoun('');
      setEmail('');
      setGuests(1);
      setDietaryRestrictions('');
      console.log(submitMessage)
      console.log(isSubmitting)
    } catch (error) {
      console.error('Error submitting RSVP:', error);
      setSubmitMessage('There was an error submitting your RSVP. Please try again.');
    }
  };


  return (
    <div className="min-h-screen relative py-12 px-4 sm:px-6 lg:px-8" style={{ backgroundImage: "url('White Gold Romantic Floral Wedding Invitation.png')", backgroundSize: "cover", backgroundPosition: "center top", height: "100vh" }}>
      {/* Overlay */}
      <div className="absolute inset-0 bg-blue-900 opacity-50 pointer-events-none"></div>

      {/* Inner content */}
      <div className="relative max-w-5xl mx-auto bg-white border-4 border-blue-900 rounded-lg shadow-xl overflow-hidden">
        <div className="px-6 py-5 sm:p-6">
          <h2 className="text-center text-3xl font-bold text-blue-900 font-serif">RSVP</h2>
          <p className="mt-2 text-center text-sm text-blue-700">
            We would be delighted to have you join us
          </p>
          <form onSubmit={handleSubmit} className="mt-8 space-y-6">
            {/* <div className="rounded-md shadow-sm space-y-4"> */}
              <FormControl className="space-y-4" fullWidth>
              <FormattedText
                label="Who are you?"
                value={name}
                onChange={(e) => setName(e.target.value)}
                id="name"
                required
              />
              
              <FormattedText
                label="What is your pronoun?"
                value={pronoun}
                onChange={(e) => setPronoun(e.target.value)}
                id="pronoun"
              />

              <FormControl fullWidth>

              <InputLabel id="attending-label" >Will you be attending?</InputLabel>
              <Select
                labelId="attending-label"
                label='Will you be attending?'

                id="demo-simple-select"
                value={attending}
                onChange={(e) => setAttending(e.target.value)}
                required
                // placeholder='Will you be attending'
                // className={`${baseInputStyles} ${paddingAndSizing} ${borderAndFocusStyles} ${colorStyles} ${shadowStyle}`}
                
              >
                {/* <MenuItem value=""></MenuItem> */}
                <MenuItem value="yes">Joyfully Accept</MenuItem>
                <MenuItem value="no">Regretfully Decline</MenuItem>
              </Select>
              </FormControl>

              {attending === 'yes' && (
                <>

              <FormattedText
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                required
              />
              <FormControl fullWidth className="space-y-4" variant="outlined" margin="normal">

                <InputLabel shrink htmlFor="guests-toggle">Number of Guests</InputLabel>
                <ToggleButtonGroup
                      value={guests}
                      exclusive
                      onChange={(e) => setGuests(e.target.value)}
                      aria-label="text alignment"
                    id="guests-toggle"

    sx={{ marginLeft: '10px' }} 
                    >
                      <ToggleButton value="1" aria-label="left aligned">
                            1 
                      </ToggleButton>
                      <ToggleButton value="2" aria-label="centered">
                           2 
                      </ToggleButton>
                    </ToggleButtonGroup>


                  </FormControl>
                  <FormattedText
                    label="Any dietary restrictions?"
                    value={dietaryRestrictions}
                    onChange={(e) => setDietaryRestrictions(e.target.value)}
                    id="dietary"
                    multiline
                    rows={3}
                  />
                </>
              )}
              </FormControl>
            {/* </div> */}

            {/* Submit button */}
            <div>
            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-full text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-300 ease-in-out transform hover:scale-105 disabled:opacity-50"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Sending...' : 'Send RSVP'}
              </button>
            </div>
            </div>
          </form>
        </div>
      </div>
      <CelebrationModal 
        isOpen={isModalOpen} 
        onClose={() => {
          setIsModalOpen(false);
          setIsSubmitting(false); // Re-enable the form after closing the modal
          setAttending('')
        }} 
        isAttending={attending === 'yes'}
      />
    </div>
  );
};

export default RusticRSVP;